import { IonButton, IonContent, IonIcon, IonPage } from '@ionic/react';
import { checkmarkCircleOutline } from 'ionicons/icons';
import React from 'react'
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { RouteState } from '../context/Context';

const MailSent:React.FC = () => {
  const {langState:language,langDispatch:{setLanguage,translate}}=RouteState()
  const history= useHistory()
  return (
    <IonPage>
    <IonContent>
        <PageContainer>
            <IonIcon icon={checkmarkCircleOutline} color='success'></IonIcon>
            <h4>{translate('mailSent')}</h4>
            <BottomDiv>
            <IonButton expand='block' className='button__action' onClick={()=>history.push('/tab2')}>{translate('continue')}</IonButton>
            </BottomDiv>
        </PageContainer>
    </IonContent>
</IonPage>
  )
}

export default MailSent
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 32px;
  h4 {
    font-weight: 400;
font-size: 24px;
line-height: 32px;
    align-self: center;
    
  }
  ion-icon{
    font-weight: bold !important;
    font-size: 42px !important;
  }
  h5{
    font-weight: bold;
    margin-bottom: 42px;
    margin-top: 6px;
  }
  ion-label {
    font-size: 12px;
  }
  height: 100%;
  position: relative;
`;
const BottomDiv = styled.div`
    position: absolute;
    bottom: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    p{
        margin-bottom: 0;
        font-size: 14px;
    }
`