import { IonContent, IonIcon, IonPage, IonText, useIonAlert } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { scan } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { Html5QrcodeScanner } from "html5-qrcode"; // Nueva librería que estás usando
import { RouteState } from '../context/Context';

const ScanPage: React.FC = () => {
  const [hideBg, setHideBg] = useState("")
  const [err, setErr] = useState<string>()
  const [present] = useIonAlert()
  const { langState: language, langDispatch: { setLanguage, translate } } = RouteState()
  const history = useHistory()

  let html5QrCodeScanner: Html5QrcodeScanner | null = null;

  const startScan = () => {

    html5QrCodeScanner = new Html5QrcodeScanner("reader", { fps: 10, qrbox: { width: 250, height: 250 }, rememberLastUsedCamera: true }, false);

    html5QrCodeScanner.render(success, error);

    function success(decodedText: string) {
      console.log(`Código QR escaneado: ${decodedText}`);
      stopScan();
      history.push('/confirmedCode/' + decodedText);
    }

    function error(err: string) {
      //console.error(`Error de escaneo: ${err}`);
    }
  };

  const stopScan = () => {
    if (html5QrCodeScanner) {
      // Aquí destruyes la instancia de Html5QrcodeScanner para detener el escaneo
      html5QrCodeScanner.clear();
      html5QrCodeScanner = null;
    }
    setHideBg("");
  };

  useEffect(() => {
    const checkPermission = async () => {
      try {
        // Verificas permisos para usar la cámara
        return true; // Simulación de permisos correctos
      } catch (error: any) {
        setErr(error.message);
        console.log(error.message);
      }
    };

    const scan = async () => {
      const permission = await checkPermission();
      if (permission) {
        startScan();
      }
    };

    scan();
    return () => {
      stopScan(); // Asegúrate de detener la cámara al salir de la página
    };
  }, []);

  return (
    <IonPage>
      <IonContent>
        <Container>
          <IonText>
            <p className="instructions">
              {translate('QrInstructions')}
            </p>
          </IonText>
          <div id="reader"></div> 
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default ScanPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  width: 100%;
  padding-top: 50px;

  ion-icon {
    font-size: 400px;
  }

  padding-left: 16px;
  padding-right: 16px;

  .instructions, #reader__dashboard_section_csr {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 24px;
    color: black;
    align-self: baseline;
    text-align: center;
  }
  #html5-qrcode-select-camera {
    color: white;
  }
  button.html5-qrcode-element {
    background-color: #ff4214;
    color: white;
    padding: 15px;
    border-radius: 48px;
    margin: 20px;
  }
`;
