import { IonButton, IonContent, IonIcon, IonLoading, IonPage, IonToast } from '@ionic/react'
import { checkmarkCircleOutline, qrCodeOutline, warningOutline } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { RouteState } from '../context/Context'
import { assignEmployee, employeeDetails } from '../services/services'

const CodeConfirmed: React.FC = () => {
    const {code}= useParams<{code:string}>()
    const history= useHistory()
    const [employee,setEmployee]=useState<any>()
    const [loading,setLoading]=useState(false)
    const [showToast,setShowToast]=useState(false)
    const {langState:language,langDispatch:{setLanguage,translate}}=RouteState()
    const{state}=RouteState()
    const assignEmployeeRoute= async(employeeId:any)=>{
      setLoading(true)
        const resp= await assignEmployee(employeeId,state.idRoute)
        if(!resp.error){
          setLoading(false)
          history.push('/tab2')
        }
        else{
          setShowToast(true)
        }
    }
    useEffect(() => {
      const getEmployee= async()=>{
        setLoading(true)
        const resp= await employeeDetails(code)
        if(!resp.error){
          setLoading(false)
          console.log(resp)
          setEmployee(resp[0])
        }
        else{
          setLoading(false)
          history.push('/deniedCode')
        }
      }
      getEmployee()
    }, [])
    
  return (
    <IonPage>
        <IonContent>
         {loading? <IonLoading isOpen={loading}></IonLoading>:
            <PageContainer>
              <IonIcon icon={qrCodeOutline} className="ion-margin-vertical" size='default'></IonIcon>
                <IonIcon icon={checkmarkCircleOutline} color='success' size='large'></IonIcon>
                <h4> {translate('confirmedCode')}:</h4>
                <h5>{code}</h5>
                {employee &&<InfoContainer>
            <p>{translate('employeeName')}: {employee.name}</p>

            <p>{translate('phone')}: {employee.phone}</p>

            <p>{translate('address')}: {employee.street} {employee.number} {employee.postalCode}, {employee.cityName}</p>
            <p>{translate('companyName')}: {employee.companyName}</p>
            <p>Date: {new Date(employee.updated_at.replace(/-/g, "/")).toLocaleString('es')}</p>
          </InfoContainer>}
          <BottomDiv>
            <IonButton expand='block' className='button__action ion-margin-top' onClick={()=>assignEmployeeRoute(employee.idEmployee)}>{translate('continue')}</IonButton>
            <IonButton fill='outline' className='button__action ion-margin-top' onClick={()=>history.push('/tab2')}>{translate('cancel')}</IonButton>
          </BottomDiv>
            </PageContainer>
            }
            <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Error: employee could not be assigned to the route. Please try again "
        icon={warningOutline}
        position="bottom"
        duration={900}
      />
        </IonContent>
    </IonPage>
  )
}

export default CodeConfirmed
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 32px;
  h4 {
    font-weight: 400;
font-size: 24px;
line-height: 32px;
    align-self: center;
    margin-bottom: 0;
  }
  ion-icon{
    font-weight: bold !important;
    font-size: 42px !important;
  }
  h5{
    font-weight: 400;
font-size: 24px;
line-height: 32px;
margin-top:0;
margin-bottom: 32px;
  }
  ion-label {
    font-size: 12px;
  }
  height: 100%;
  position: relative;
`;

const InfoContainer = styled.div`
  width: 90%;
  background: white;
  box-shadow: 0px 0.5px 1px 2px rgba(0, 0, 0, 0.11);
border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  justify-content: center;
  padding: 24px 16px;
  p{
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  margin-bottom: 16px;
`;
const BottomDiv = styled.div`
    position: absolute;
    bottom: 32px;
    display: flex;
    
    align-items: center;
    justify-content: space-between;
    width: 70%;
    p{
        margin-bottom: 0;
        font-size: 14px;
    }
`