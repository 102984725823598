import {
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { chevronForwardSharp } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { RouteState } from "../context/Context";
import { routes } from "../services/services";
import "./Tab1.css";

const Tab1: React.FC = () => {
  const history=useHistory()
  const {userState,state}=RouteState()
  const [routesList,setRoutesList]=useState([])
  const[loading,setLoading]=useState(false)
  const {langState:language,langDispatch:{setLanguage,translate}}=RouteState()
  useEffect(()=>{
    
    const name = localStorage.getItem('name');
    if (!name) {
      history.replace('/principal');
    }
    const getRoutes= async()=>{
      setLoading(true)
      const resp= await routes(localStorage.getItem("idDriver"))
      console.log(localStorage.getItem("idDriver"))
      if(!resp.error){
        setLoading(false)
        
        setRoutesList(resp)
      }
    }
    getRoutes()
  },[state])
  return (
    <IonPage>
      <IonContent fullscreen>
        <PageContainer>
          <h3>{translate('routeHistory')}</h3>
          <h4>{new Date().toJSON().slice(0,10).split('-').reverse().join('/')}</h4>
          {
            
            routesList.length>0 && routesList.filter((route:any)=> route.idRoute===localStorage.getItem('idRoute')).map((route:any)=>(
              <ContainerCard onClick={()=>history.push("/list/"+route.idRoute)} key={route.idRoute}>
          <HistoryCard>
            <p>{translate('company')} : {route.name}</p>
            <p>{translate('NroUnit')} : <strong>{route.vehicleNumber}</strong></p>

            <p>{translate('boardingPoint')}:</p>
            <p><strong>{route.boardingPoint}</strong></p>
            <p>Date: {new Date(route.route_date.replace(/-/g, "/")).toLocaleString('es')}</p>
            <div>
            <p className="margin__right">{translate('route')}:</p>
            <p className="route__type"> {route.type==="aller"?translate('go'):translate('return')}</p>
            </div>
            <div>
              <p className="state__current margin__right">{translate('state')}: </p>
              <p className={route.state==='1'?"state__current":"route__type"}>{route.state==='1'?translate("current"):translate("finished")}</p>
            </div>
            
          
          </HistoryCard>
          <EmployeeButton >
            <IonIcon icon={chevronForwardSharp}></IonIcon>
          </EmployeeButton>
        </ContainerCard>
            ))
          }
          
          
          <h4>{translate("pastRoutes")}</h4>
            {
              routesList.length>0 && routesList.filter((route:any)=> route.idRoute!==localStorage.getItem('idRoute')).reverse().map((route:any)=>(
                <ContainerCard onClick={()=>history.push("/list/"+route.idRoute)} key={route.idRoute}>
            <HistoryCard>
              <p>{translate('company')} : {route.name}</p>
              <p>{translate("NroUnit")}: <strong>{route.vehicleNumber}</strong></p>
  
              <p>{translate("boardingPoint")}:</p>
              <p><strong>{route.boardingPoint}</strong></p>
              <div>
              <p className="margin__right">{translate('route')}:</p>
              <p className="route__type"> {route.type==="aller"?translate('go'):translate('return')}</p>
              </div>
              <p>Date: {new Date(route.route_date.replace(/-/g, "/")).toLocaleString('es')}</p>
              <div>
                <p className="state__current margin__right">{translate('state')}: </p>
                <p className={route.state==='1'?"state__current":"route__type"}>{route.state==='1'?translate('current'):translate("finished")}</p>
              </div>
              
            
            </HistoryCard>
            <EmployeeButton >
              <IonIcon icon={chevronForwardSharp}></IonIcon>
            </EmployeeButton>
          </ContainerCard>))
            }
          
        </PageContainer>
      </IonContent>
    </IonPage>
  );
};

export default Tab1;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 24px 16px;
  h3{
    font-weight: 400;
font-size: 24px;
line-height: 32px;

/* identical to box height, or 133% */
display: flex;
align-items: center;
text-align: center;


  }
  h4 {
    font-weight: 400;
font-size: 16px;
line-height: 24px;

/* or 150% */
text-align: center;
letter-spacing: 0.5px;


  }
`;
const HistoryCard = styled.div`
  background: #ffffff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 15px;
  align-items: flex-start;
  justify-content: center;
  color: black;
  p {
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 500;
font-size: 14px;
line-height: 11px;

  }
  
  div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 5px;
    
    padding-left: 0;
  }
`;

const EmployeeButton=styled.div`
  background-color: #FF4214;
  color: white;
  width: 10%;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 187px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;

`

const ContainerCard= styled.div`
  display: flex;
  align-content:center;
  width:90%;
  box-shadow: 0px 0.5px 1px 2px rgba(0, 0, 0, 0.11);
border-radius: 24px;
margin-top: 6px;
  margin-bottom: 16px;
  background-color: white;
`