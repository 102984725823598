import React, { ReactNode, useContext, useReducer } from "react";
import { createContext } from "react";
import { langReducer, routeReducer, userReducer } from "./Reducers";
import {default as en} from '../i18n/translations/en.json' ;
import {default as fr} from '../i18n/translations/fr.json';
type Route={
   idRoute:string,
    enterprise: string;
    vehicleNumber:string;
    startingPoint: string;
    routeType: string;
    date:string;
}


interface LangState {
  language: string;
}

interface LangStateProps {
  children: ReactNode;
}

enum LangActionType {
  SET_LANGUAGE = 'SET_LANGUAGE'
}

interface ContextProps {
  state: LangState;
  dispatch: {
    setLanguage: (lang: string) => void;
    translate: (key: string) => string;
  }
}

 const initialRoute={
   idRoute:"",
    enterprise:"",
      vehicleNumber:"",
      startingPoint:"",
      routeType:"",
      date:""
 }
 const initialUser={
   id:"",
               name:"",
                email:"",
                password:"",
                state:"",
                driverNumber:"", 
 }
 const localStorageLang = localStorage.getItem('language');
const initialLanguageState = {
  language: localStorageLang ? localStorageLang : 'EN'
}
export const AppContext = createContext<{
    state: Route;
    dispatch: React.Dispatch<any>;
   userState: any;
   userDispatch: React.Dispatch<any>;
   langState: LangState;
  langDispatch: {
    setLanguage: (lang: string) => void;
    translate: (key: string) => string;
  }
 }>({
    state: initialRoute,
    dispatch: ()=>null,
    userState: initialUser,
    userDispatch: ()=>null,
    langState:initialLanguageState,
    langDispatch:{
      setLanguage:(lang:string)=>null,
      translate:(key:string)=>""
    }
 })

 
 const Context:React.FC=({children})=>{
    const [state, dispatch] = useReducer(routeReducer, initialRoute);
    const [userState,userDispatch]=useReducer(userReducer,initialUser);
    const [langState,langDispatch]=useReducer(langReducer,initialLanguageState);
    const setLanguage = (lang: string) => {
      localStorage.setItem('language', lang);
      langDispatch({
        type: LangActionType.SET_LANGUAGE,
        payload: lang
      });
    }
  
    const translate = (key: string): string => {
      const { language } = langState;
      let langData: { [key: string]: string } = {};
      
      if(language === 'EN') {
        langData = en;
        
      }else if(language === 'FR') {
        langData = fr;
        console.log("fr")
      }
     
      return langData[key];
    }
    return (
      <AppContext.Provider value={{ state, dispatch,userState,userDispatch, langState,langDispatch:{setLanguage,translate}}}>
        {children}
      </AppContext.Provider>
    );
 }

 export default Context;

 export const RouteState = () => {
    return useContext(AppContext);
  };