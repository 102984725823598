import { IonButton, IonContent, IonIcon, IonPage } from '@ionic/react'
import React from 'react'
import styled from 'styled-components'
import { alertCircleOutline } from 'ionicons/icons'
import { useHistory } from 'react-router'
import { RouteState } from '../context/Context'

const CodeDenied:React.FC = () => {
  const history= useHistory()
  const {langState:language,langDispatch:{setLanguage,translate}}=RouteState()
  return (
    <IonPage>
        <IonContent>
            <PageContainer>
                <IonIcon icon={alertCircleOutline} color='danger'></IonIcon>
                <h4>{translate('wrongCode')}</h4>
                <BottomDiv>
                <IonButton expand='block' className='button__action' onClick={()=>history.push('/tab2')}>{translate('continue')}</IonButton>
                </BottomDiv>
            </PageContainer>
        </IonContent>
    </IonPage>
  )
}

export default CodeDenied

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 32px;
  h4 {
    font-weight: 400;
font-size: 24px;
line-height: 32px;
    align-self: center;
    
  }
  ion-icon{
    font-weight: bold !important;
    font-size: 42px !important;
  }
  h5{
    font-weight: bold;
    margin-bottom: 42px;
    margin-top: 6px;
  }
  ion-label {
    font-size: 12px;
  }
  height: 100%;
  position: relative;
`;
const BottomDiv = styled.div`
    position: absolute;
    bottom: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    p{
        margin-bottom: 0;
        font-size: 14px;
    }
`