import { IonButton, IonContent, IonHeader, IonIcon, IonInput, IonLabel, IonPage } from '@ionic/react'
import React, { useState } from 'react'
import styled from 'styled-components'
import { personCircleOutline, mailOutline, eyeOutline,eyeOffOutline, chevronBackOutline } from 'ionicons/icons'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router'
interface IUser{
    name?: string;
    mail?: string;
    password?: string;
    driver?:string;
}
const Register:React.FC = () => {
  const history= useHistory()
  const [showPassword,setShowPassword]=useState(false)
    const initialUser:IUser={
        name: "",
        mail: "",
        password:"",
        driver:""
    }
    const formik = useFormik({
        initialValues: initialUser,
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Name is required").
            matches(/^[a-zA-ZÀ-ÿ-. ]*$/,"Name must contain just letters and spaces"),
            mail: Yup.string().email("email is not valid").required("email is required"),
            password: Yup.string().min(5,"password must contain at least 5 characters").required("password is required"),
            driver: Yup.number()
            .typeError("Driver number must contain only numbers")
            .integer("Driver number cannot contain character '.'")
            .required("Driver number is required"),
        }),
        onSubmit: async(values)=>{

        }
    })
  return (
    <IonPage>
      <IonHeader className="ion-no-border ion-padding-top ion-padding-horizontal">
        <IonButton
          fill="clear"
          style={{"--color":'var(--ion-color-secondary)' }}
          
          onClick={()=>{history.goBack()}}
        >
          <IonIcon
            icon={chevronBackOutline}
            slot="start"
            size="small"
          ></IonIcon>
          RETOUR
        </IonButton>
      </IonHeader>
        <IonContent >
            <PageContainer>
                <h4>S’inscrire</h4>
                <FormCard>
                <form onSubmit={(e) => {
              e.preventDefault();
            }}
            autoComplete="nope"
            className='form__align'>
                <p>Nom du conducteur</p>
                <CustomInput placeholder='John Carter'
                name="name"
                type="text"
                onIonChange={formik.handleChange}
                onIonBlur={formik.handleBlur}
                value={formik.values.name}
                >
                    <IonIcon  icon={personCircleOutline} slot='start' className='ion-margin-horizontal' color='medium' size='small'>

                    </IonIcon>
                </CustomInput>
                {formik.touched.name && formik.errors.name && <IonLabel className="error__label">{formik.errors.name}</IonLabel>}
                <p>Courriel</p>
                <CustomInput placeholder='charles@gmail.com'
                name="mail"
                type="text"
                onIonChange={formik.handleChange}
                onIonBlur={formik.handleBlur}
                value={formik.values.mail}
                >
                    <IonIcon icon={mailOutline} slot='start' className='ion-margin-horizontal' color='medium' size='small'>

                    </IonIcon>
                </CustomInput>
                {formik.touched.mail && formik.errors.mail && <IonLabel className="error__label">{formik.errors.mail}</IonLabel>}
                <p>Mot de passe</p>
                <CustomInput placeholder='&bull;&bull;&bull;&bull;&bull;&bull;'
                type={showPassword? "text":"password"}
                name='password'
                onIonChange={formik.handleChange}
                onIonBlur={formik.handleBlur}
                value={formik.values.password}
                >
                    <IonIcon onClick={()=>setShowPassword(true)} icon={showPassword? eyeOffOutline :eyeOutline} slot='start' className='ion-margin-horizontal' color='medium' size='small'>

                    </IonIcon>
                </CustomInput>
                {formik.touched.password && formik.errors.password && <IonLabel className="error__label">{formik.errors.password}</IonLabel>}
                <p>Numéro de conducteur</p>
                <CustomInput placeholder='12345650' className='center-text'
                name="driver"
                type="text"
                onIonChange={formik.handleChange}
                onIonBlur={formik.handleBlur}
                value={formik.values.driver}
                ></CustomInput>
                {formik.touched.driver && formik.errors.driver && <IonLabel className="error__label">{formik.errors.driver}</IonLabel>}
          
                    <IonButton  className='button__action ion-margin-vertical' onClick={()=>formik.handleSubmit()}>
                    S’INSCRIRE
                    </IonButton>
                
                </form>
              </FormCard>
            </PageContainer>
        </IonContent>
    </IonPage>
  )
}

export default Register
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 12px 32px;
  h4{
    font-weight: 400;
font-size: 24px;
line-height: 32px;
align-self: center;
margin-bottom: 24px;
    
  }
  ion-label{
    font-size:12px;
  }
`;
const CustomInput = styled(IonInput)`
  background: white;
  border: 1px solid #eff0f7;
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 46px;

`;

const BottomDiv = styled.div`
    position: absolute;
    bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    p{
        margin-bottom: 0;
        font-size: 14px;
    }
`
const FormCard=styled.div`
  background: #C0CDD9;
  box-shadow: 0px 0.5px 1px 2px rgba(0, 0, 0, 0.11);
  border-radius: 24px;
  width:100%;
  padding: 16px;
  p{
    font-weight: 500;
font-size: 14px;
line-height: 20px;
margin-bottom: 6px;
  }

`