import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonToast,
} from "@ionic/react";
import { chevronBackOutline, warningOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { RouteState } from "../context/Context";
import { assignEmployee, employeeDetails } from "../services/services";

const ConfirmRegister: React.FC = () => {
  const history= useHistory()
  const {id}= useParams<any>()
  const [loading,setLoading]=useState(false)
  const[showToast,setShowToast]=useState(false)
  const [employee,setEmployee]=useState<any>()
  const{state}=RouteState()
  const assignEmployeeRoute= async(employeeId:any)=>{
    setLoading(true)
    console.log(localStorage.getItem('idRoute'))
    const resp= await assignEmployee(employeeId,localStorage.getItem('idRoute'))
    if(resp.id!=='0'){
      setLoading(false)
      history.push('/tab2')
    }
    else{
      setLoading(false)
      setShowToast(true)
    }
}
  useEffect(()=>{
    const getEmployee= async()=>{
      const resp= await employeeDetails(id)
      if(!resp.error){
        setEmployee(resp[0])
      }
    }
    getEmployee()
  },[])
  return (
    <IonPage>
      {loading?<IonLoading isOpen={loading}></IonLoading>:<>
      <IonHeader className="ion-no-border ion-padding">
      <IonButton
          fill="clear"
          style={{"--color":'var(--ion-color-secondary)' }}
          
          onClick={()=>{history.goBack()}}
        >
          <IonIcon
            icon={chevronBackOutline}
            slot="start"
            size="small"
          ></IonIcon>
          RETOUR
        </IonButton>
      </IonHeader>
      <IonContent>
        <PageContainer>
          <h4>Revoir l’information saisie</h4>
          <InfoContainer>
            <p>Nom d’employé: {employee.name}</p>

            <p>Téléphone: {employee.phone}</p>

            <p>Adresse: {employee.street} {employee.number}</p>
          </InfoContainer>
          
            <p className='confirm'>Confirmer les renseignements et finaliser?</p>
            <ButtonContainer>
            <IonButton className="button__action" onClick={()=>assignEmployeeRoute(employee.employeeId)}>CONTINUER</IonButton>
                <IonButton fill='outline' className="button__action" onClick={()=>history.goBack()}>ANNULER</IonButton>
                
            </ButtonContainer>
          
          
        </PageContainer>
        <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Error: employee could not be registered for this route "
        icon={warningOutline}
        position="bottom"
        duration={900}
      />
      </IonContent>
      </>}
    </IonPage>
  );
};

export default ConfirmRegister;
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  width: 100%;
  padding: 12px 28px;
  h4{
    font-weight: 400;
font-size: 24px;
line-height: 32px;
align-self: center;
margin-bottom: 24px;
  }
  ion-label {
    font-size: 12px;
  }
  height: 100%;
  position: relative;

`;
const BottomDiv = styled.div`
    position: absolute;
    bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    p{
        margin-bottom: 0;
        font-size: 14px;
    }
`
const ButtonContainer = styled.div`
  display: flex;
  padding: 18px 16px;
  
  width: 92%;
  align-items: center;
  justify-content: space-between;
  
`;
const InfoContainer = styled.div`
  width: 90%;
  background: white;
  box-shadow: 0px 0.5px 1px 2px rgba(0, 0, 0, 0.11);
border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  justify-content: center;
  padding: 24px 16px;
  p{
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;
