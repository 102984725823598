export const routeReducer=(state:any, action:any)=>{
    switch (action.type) {
        case "ADD_ROUTE":
            return action.payload
        case "CLEAR":{

        console.log("clear")
            return {
                idRoute:"",
                enterprise:"",
                vehicleNumber:"",
                startingPoint:"",
                routeType:"",
                date:""
            }
        }
        default:
            return state;
    }
}
enum LangActionType {
    SET_LANGUAGE = 'SET_LANGUAGE'
  }
interface LangState {
    language: string;
  }
  interface SetLanguageAction {
    type: typeof LangActionType.SET_LANGUAGE;
    payload: string;
  }
export const langReducer = (langState: LangState, action: SetLanguageAction): LangState => {
    switch(action.type) {
      case LangActionType.SET_LANGUAGE:
        return {
          language: action.payload
        }
      default:
        return langState;
    }
  }
  

export const userReducer=(state:any,action:any)=>{
    switch(action.type){
        case "LOGIN":
            return action.payload
        case "LOGOUT":
            return {
                id:"",
                name:"",
                email:"",
                password:"",
                state:"",
                driverNumber:""
            }
        default:
            return state;
    }
}