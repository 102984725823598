import { IonButton, IonCard, IonContent, IonHeader, IonIcon, IonLoading, IonModal, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import styled from 'styled-components';
import './Tab2.css';
import { qrCodeOutline, pencilOutline, checkmarkCircleOutline, chevronForwardSharp } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { RouteState } from '../context/Context';
import { useEffect, useRef, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import {App} from '@capacitor/app'
import { route, finishRoute as endRoute } from '../services/services';
const Tab2: React.FC = () => {
  const {state,dispatch}=RouteState()
  const [currentRoute,setCurrentRoute]=useState<any>(state)
  const [loading,setLoading]=useState(false)
  const[ableButton,setAbleButton]=useState(true)
  const history = useHistory()
  const [showAlert, setShowAlert]=useState<boolean>(false)
  const modalRef = useRef<HTMLIonModalElement>(null);
  document.body.style.background = "";
  document.body.style.opacity="1";
  const {langState:language,langDispatch:{setLanguage,translate}}=RouteState()
  const finishRoute=async()=>{
    //changeState
    setLoading(true)
    setShowAlert(false)
    modalRef.current?.dismiss();
    const resp= await endRoute(localStorage.getItem('idRoute'),0)
    if(resp.res==="actualizado correctamente")
    {
      console.log("Este lo borra")
      localStorage.removeItem('idRoute')
      dispatch({type:"CLEAR"})
      console.log(state)
      setCurrentRoute({
        idRoute:"",
        enterprise:"",
        vehicleNumber:"",
        startingPoint:"",
        routeType:"",
        date:""
    })
    

      setAbleButton(true)
      
      setLoading(false)
    }
    
  }
  
  useEffect(() => {
    const name = localStorage.getItem('name');
    if (!name) {
      history.replace('/principal');
    }
    const routeDetails= async(id:any)=>{
      console.log(id)
      const resp= await route(id)
      if(!resp.error && resp.length>0){
        console.log("aqui:", resp[0])
        // const route={
        //   idRoute:resp[0].idRoute,
        //   enterprise:resp[0].companyName,
        //   startingPoint: resp[0].boardingPoint,
        //   routeType:resp[0].type,
        //   vehicleNumber:resp[0].vehicleNumber,
        //   date: resp[0].route_date
        // }
        // dispatch({
        //   type:'ADD_ROUTE',
        //   payload:route
        // })
        setCurrentRoute(resp[0])
        setAbleButton(false)
      }
      else{
        if(localStorage.getItem("idRoute")){
          localStorage.removeItem("idRoute")
          setCurrentRoute({
            idRoute:"",
            enterprise:"",
            vehicleNumber:"",
            startingPoint:"",
            routeType:"",
            date:""
        })
        }
      }
    }
    if(localStorage.getItem('idRoute')){
      console.log("callRoute")
      routeDetails(localStorage.getItem('idRoute'))
    }
    else{
      setCurrentRoute({
        idRoute:"",
        enterprise:"",
        vehicleNumber:"",
        startingPoint:"",
        routeType:"",
        date:""
    })
    }
    console.log(state)
    if (Capacitor.isNativePlatform()) {
      App.addListener('backButton', (e:any) => {
           // Use of location.pathname is also correct
        if (window.location.pathname === '/tab2') {
        App.exitApp()
        } 
         else {
          history.goBack()
        }
      })
    }
    window.addEventListener('storage', routeDetails)

  return () => {
    window.removeEventListener('storage', routeDetails)
  }
   }, [state])
  return (
    <IonPage>
      
      <IonContent fullscreen>
        {loading?<IonLoading isOpen={loading}></IonLoading>:
        <PageContainer>
          
          {currentRoute.idRoute==="" || currentRoute===undefined?<NoRouteCard>
           
            <p className='ion-margin-horizontal'>{translate("newRouteInstructions")}</p>
          
            <EmployeeButtonDisabled>
            <IonIcon icon={chevronForwardSharp}></IonIcon>
          </EmployeeButtonDisabled>
          </NoRouteCard>:
         currentRoute!==undefined && <RouteCard onClick={()=>history.push('/list/'+currentRoute.idRoute)}>
          <HistoryCard>
            <p>{translate('company')} : {currentRoute.companyName}</p>
            <p>{translate('NroUnit')} : <strong>{currentRoute.vehicleNumber}</strong></p>

            <p>{translate('boardingPoint')}:</p>
            <p><strong>{currentRoute.boardingPoint}</strong></p>
            {currentRoute.route_date && <p>Date: {new Date(currentRoute.route_date.replace(/-/g, "/")).toLocaleString('es')}</p>}
            <div>
            <p className="margin__right">{translate('route')}:</p>
            <p className="route__type"> {currentRoute.type==="aller"?translate("go"):translate("return")}</p>
            </div>
          </HistoryCard>
          <EmployeeButton>
            <IonIcon icon={chevronForwardSharp}></IonIcon>
          </EmployeeButton>
          </RouteCard>
          }
          <IonButton color='primary' expand='block' style={{'--border-radius': '48px'}} onClick={()=>history.push('/selectRoute')} disabled={currentRoute.idRoute===''?false:true}>{translate('newRoute')}</IonButton>
          <p>{translate('SelectActivity')}</p>
          <CardContainer>
            <ButtonCard button disabled={currentRoute.idRoute===''?true:false} onClick={()=>history.push('/scan')}>
              <IonIcon icon={qrCodeOutline} size='large'></IonIcon>
              <p>{translate('scanCode')}</p>
            </ButtonCard>
            <ButtonCard disabled={currentRoute.idRoute===''?true:false} button onClick={()=>{history.push('/manualRegister')}}>
              <IonIcon icon={`${process.env.PUBLIC_URL}/assets/icon/register.svg`} size='large'></IonIcon>
              <p>{translate('manualRegisterLabel')}</p>
            </ButtonCard>
          </CardContainer>
          <CheckedRoute disabled={currentRoute.idRoute===''?true:false} button onClick={()=>{
            setShowAlert(true)
          }}>
            <IonIcon icon={`${process.env.PUBLIC_URL}/assets/icon/MobileRegisterIcon.svg`} size='large'></IonIcon>
            <p>{translate('finishRegister')}</p>
          </CheckedRoute>
          
        </PageContainer>
        }
       
      </IonContent>
      {showAlert && 
    <div>
    <IonModal isOpen={showAlert} ref={modalRef} id='example-modal' >
        
        <AlertContainer>
          <IonIcon icon={`${process.env.PUBLIC_URL}/assets/icon/alert.svg`} color="secondary" size='large'></IonIcon>
          <p>{translate('confirmFinishing')}</p>
          <ButtonDiv>
          <IonButton className='button__action' onClick={finishRoute}>
              {translate('finish')}
            </IonButton>
            <IonButton fill='outline' className='button__action' onClick={()=>modalRef.current?.dismiss()}>
                {translate('cancel')}
            </IonButton>
            
          </ButtonDiv>
        </AlertContainer>
        
     
    </IonModal>
    </div>}
    </IonPage>
  );
};

export default Tab2;

const PageContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
padding: 24px 16px;

`
const NoRouteCard = styled.div`
  display: flex;
  background-color: white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  width: 90%;
  color: #00539F;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 12px;
  min-height: 138px; 
  box-shadow: 0px 0.5px 1px 2px rgba(0, 0, 0, 0.11);
border-radius: 24px;
`
const ButtonCard = styled(IonCard)`
  background: #FFFFFF;
  border-radius: 25px;
  color: #FF4214;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 14px 9px;
  padding-top:18px ;
  padding-inline: 12px;
  text-align: center;
  border: 1px solid #FF4214;
  width:50%;
  height:119px;
`
const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  
`
const CheckedRoute= styled(IonCard)`
  display: flex;
  flex-direction:column;
  background-color: white;

  border-radius: 25px;
  width: 80%;
  color: #FF4214;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 24px;
  text-align: center;
  padding-top: 18px;
  min-height: 119px;
  border: 1px solid #FF4214;
`
const RouteCard = styled(IonCard)`
  display: flex;
  flex-direction:row;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
  color: var(--ion-color-secondary);
background-color: white;
border-radius: 24px;
`
const EmployeeButton=styled.div`
  background-color: #FF4214;
  color: white;
  width: 8%;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 161px;
  

`
const EmployeeButtonDisabled=styled.div`
  background-color: #E0E0E0;
  color: white;
  width: 8%;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 140px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  align-self: flex-end;

`
const HistoryCard = styled.div`
  background: #ffffff;
  height:100%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  width: 92%;
  padding: 15px;
  align-items: flex-start;
  justify-content: center;
  p {
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 500;
font-size: 14px;
line-height: 11px;
  }
  
  div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 5px;
    
    padding-left: 0;
  }
`;

const AlertContainer=styled.div`
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  background-color:white;
  width:90%;
  p{
    color: black;
    text-align: center;
  }
  
`
const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:90%;


`