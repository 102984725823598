import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, triangle } from 'ionicons/icons';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';
import './App.css'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import SelectRoute from './pages/SelectRoute';
import ManualRegister from './pages/ManualRegister';
import ConfirmRegister from './pages/ConfirmRegister';
import CodeConfirmed from './pages/CodeConfirmed';
import CodeDenied from './pages/CodeDenied';
import {gitCompareOutline, personOutline,compassOutline} from 'ionicons/icons'
import Register from './pages/Register';
import Login from './pages/Login';
import Principal from './pages/Principal';
import ScanPage from './pages/ScanPage';
import PeopleList from './pages/PeopleList';
import Context, { RouteState } from './context/Context';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import ShareWorkers from './pages/ShareWorkers';
import MailSent from './pages/MailSent';
import { useContext, useEffect, useState } from 'react';
setupIonicReact();

const App: React.FC = () => {
  const {langState:language,langDispatch:{setLanguage,translate}}=RouteState()
  const[lang,setLang]=useState("EN")
  useEffect(()=>{
    if(localStorage.getItem('language')){
      console.log(language)
      
      setLang(localStorage.getItem('language')!)
    }
    
  },[language])
  return(
  <Context>
  <IonApp>
    <IonReactRouter>
      <IonTabs >
        <IonRouterOutlet>
          <Route exact path="/tab1">
            <Tab1 />
          </Route>
          <Route exact path="/tab2">
            <Tab2 />
          </Route>
          <Route path="/tab3">
            <Tab3 />
          </Route>
          <Route exact path="/">
            {localStorage.getItem("Auth")?<Redirect to="/tab2" />:<Redirect to="principal"/>}
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom" >
          <IonTabButton tab="tab1" href="/tab1" >
            <IonIcon icon={`${process.env.PUBLIC_URL}/assets/icon/traject.svg`} />
            <IonLabel>{language.language==="EN"?'History':"Historique"}</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab2" href="/tab2">
            <IonIcon icon={`${process.env.PUBLIC_URL}/assets/icon/route.svg`} />
            <IonLabel>{language.language==="EN"?'Plan trip':"Planifier trajet"}</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab3" href="/tab3">
            <IonIcon icon={`${process.env.PUBLIC_URL}/assets/icon/user.svg`} />
            <IonLabel>{language.language==="EN"?'Account':"Compte"}</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      <Route path='/selectRoute' exact>
        <SelectRoute/>
      </Route>
      <Route path='/manualRegister' exact>
        <ManualRegister/>

      </Route>
      <Route path='/confirmRegister/:id' exact>
        <ConfirmRegister/>
      </Route>
      <Route  path='/confirmedCode/:code'>
          <CodeConfirmed/>

      </Route>
      <Route path='/deniedCode'>
        <CodeDenied/>
      </Route>
      <Route path='/register'>
        <Register/>
      </Route>
      <Route path='/login'>
        <Login/>
      </Route>
      <Route path='/principal'>
        <Principal/>
      </Route>
      <Route path='/scan'>
        <ScanPage/>
      </Route>
      <Route path='/list/:id'>
          <PeopleList/>
      </Route>
      <Route path='/forgotPassword'>
          <ForgotPassword/>
      </Route>
      <Route path='/changePassword'>
          <ChangePassword/>
      </Route>
      <Route path='/share/:id'>
          <ShareWorkers/>
      </Route>
      <Route path='/mailSuccess'>
          <MailSent/>
      </Route>
    </IonReactRouter>
  </IonApp>
</Context>
);}

export default App;
