import { IonButton, IonContent, IonLabel, IonPage, IonSelect, IonSelectOption } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { RouteState } from "../context/Context";

const Principal: React.FC = () => {
  const history= useHistory()
  const {langState:language,langDispatch:{setLanguage,translate}}=RouteState()
  const changeLanguage=(e:any)=>{
    console.log(e.detail.value)
    setLanguage(e.detail.value)
    console.log(translate('logIn'))
  }
  return (
    <IonPage>
      <IonContent>
        <PageContainer>
          <LoginImg>
            <img src={`${process.env.PUBLIC_URL}/assets/header-app.png`}/>
          </LoginImg>
          
          <h1>{translate('title1')}</h1>
          <h2>{translate('title2')}</h2>
          <Image className='ion-margin-horizontal'>
            <img src={`${process.env.PUBLIC_URL}/assets/OTIKA SERVICES-Logo 1.png`}/>
          </Image>
          <IonLabel>Language</IonLabel>
          <IonSelect onIonChange={changeLanguage} placeholder="language" interface="popover">
            <IonSelectOption value="EN">English</IonSelectOption>
            <IonSelectOption value="FR">French</IonSelectOption>
          </IonSelect>
          <LoginButton  onClick={()=>history.push("/login")} className='ion-margin-vertical'>{translate("logIn")}</LoginButton>
          
        </PageContainer>
      </IonContent>
    </IonPage>
  );
};

export default Principal;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 32px;
  h1 {
    font-weight: 400;
font-size: 36px;
line-height: 44px;
    color: #ff4214;
    margin-bottom: 0;
    text-align: center;
  }
  p {
    color: black;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-top:24px;
    margin-bottom:15px;
  }
  h2 {
    font-weight: 400;
font-size: 36px;
line-height: 44px;
    margin-top: 0;
  }
  ion-icon {
    font-weight: bold !important;
    font-size: 42px !important;
  }
  h5 {
    font-weight: bold;
    margin-bottom: 42px;
    margin-top: 6px;
  }
  ion-label {
    font-size: 12px;
  }
  height: 100%;
  position: relative;
`;
 const RegisterButton= styled(IonButton)`
    --border-radius: 48px;
    --box-shadow: 0px 1px 2px 4px rgba(0, 0, 0, 0.12);
    
    margin-top: 15px;
    margin-bottom: 15px;
    --width: 124px;
height: 42px;
 `

 const LoginButton = styled(IonButton)`
    --width: 124px;
    height: 42px;
   position:absolute;
   bottom: 30px;
    --border-radius: 48px;
    
    
    margin-top: 15px;
    margin-bottom: 15px;
 `

const Image = styled.div`
    position: absolute;
    bottom: 110px;
    object-fit: cover;
    object-position: center;
`
const BottomDiv = styled.div`
    position: absolute;
    bottom: 80px;
    display: flex;
    
    align-items: center;
    justify-content: space-between;
    width: 75%;
    p{
        margin-bottom: 0;
        font-size: 14px;
    }
`
const LoginImg = styled.div`
    position : absolute;
    top:0;
    width:100%;
    height: 160px;
    object-fit:cover;
    object-position:center;
`