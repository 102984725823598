import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
} from "@ionic/react";
import { chevronBackOutline, person } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { RouteState } from "../context/Context";
import { EmployeesRoute, route } from "../services/services";


const PeopleList: React.FC = () => {
    const history= useHistory()
    const [employees,setEmployees]=useState([])
    const [selectedRoute,setSelectedRoute]=useState<any>()
    const{state}=RouteState()
    const[loading,setLoading]=useState(false)
    const[showToast,setShowToast]=useState(false)
    const {id}= useParams<any>()
    const {langState:language,langDispatch:{setLanguage,translate}}=RouteState()
  useEffect(() => {
    const routeDetails= async()=>{
      setLoading(true)
      const resp= await route(id)
      if(!resp.error){
        console.log(resp)
        setSelectedRoute(resp[0])
        setLoading(false)
      }
    }
  const loadList= async()=>{
    const resp= await EmployeesRoute(id)
    console.log(resp)
    setEmployees(resp.reverse())
  }
  routeDetails()
  loadList()
    
  }, [])
  
  return (
    <IonPage>
      {loading? <IonLoading isOpen={loading}></IonLoading>:<>
      <IonHeader className="ion-no-border ion-padding">
        <HeaderContainer>
        <IonButton
          fill="clear"
          style={{"--color":'var(--ion-color-secondary)' }}
          
          onClick={()=>{history.goBack()}}
        >
          <IonIcon
            icon={chevronBackOutline}
            slot="start"
            size="small"
          ></IonIcon>
          {translate('back')}
        </IonButton>
          <IonButton
            color="primary"
            style={{ "--border-radius": "8px", width: "130px" }}
            className="ion-margin-horizontal"
            onClick={()=>history.push('/share/'+id)}
          >
            <IonIcon
              icon={`${process.env.PUBLIC_URL}/assets/icon/share.svg`}
              slot="start"
              size="small"
            ></IonIcon>
            {translate('share')}
          </IonButton>
        </HeaderContainer>
      </IonHeader>
      <IonContent>
        <PageContainer>
          {selectedRoute && <HistoryCard>
            <p>{translate('company')} : {selectedRoute?.companyName}</p>
            <p>
              {translate('NroUnit')} : <strong>{selectedRoute?.vehicleNumber}</strong>
            </p>

            <p>{translate('boardingPoint')}:</p>
            <p>
              <strong>{selectedRoute?.boardingPoint}</strong>
            </p>
            <p>Date: {new Date(selectedRoute.route_date.replace(/-/g, "/")).toLocaleString('es')}</p>
            <div>
              <p className="margin__right">{translate('route')}:</p>
              <p className="route__type"> {selectedRoute?.type==='aller'?translate('go'):translate('return')}</p>
            </div>
            
          </HistoryCard>}
          <HeaderContainer >
            <p className='ion-margin-horizontal'>{translate('registerRoute')}</p>

            <p className='ion-margin-horizontal'>
              <IonIcon icon={`${process.env.PUBLIC_URL}/assets/icon/employee.svg`}> </IonIcon> {employees.length>0? employees.length:0}
            </p>
          </HeaderContainer>
          {employees.length>0 && employees.map((employee:any)=>{
            console.log(employees)
            return(
            <EmployeeCard key={employee.created_at}>
              
              <p>{translate('employeeName')}: {employee.name} </p>
              <p>{translate('phone')}: {employee.phone}</p>
              <p>{translate('address')}: {employee.street} {employee.number}, {employee.postalCode}, {employee.cityName}</p>
              <p>Date: {new Date(employee.created_at.replace(/-/g, "/")).toLocaleString('es')}</p>
            </EmployeeCard>
          )})}
          
        </PageContainer>
      </IonContent>
    </>}
    </IonPage>
  );
};

export default PeopleList;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 24px 16px;
  h4 {
    font-weight: bold;
    font-size: 14px;
  }
`;
const HistoryCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 0.5px 1px 2px rgba(0, 0, 0, 0.11);
border-radius: 24px;
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 15px;
  align-items: flex-start;
  justify-content: center;
  p {
    margin-top: 5px;
    
    font-weight: 500;
    font-size: 14px;
    line-height: 11px;
  }


  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 5px;

    padding-left: 0;
  }
  margin-bottom: 16px;
`;
const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  p {
    font-weight: 500;
font-size: 16px;
line-height: 24px;
  }
`;

const EmployeeCard= styled.div`
border-radius: 24px;
height:136px;
margin-bottom: 16px;
display: flex;
  flex-direction: column;
  width: 90%;
  padding: 18px;
  align-items: flex-start;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0.5px 1px 2px rgba(0, 0, 0, 0.11);
  p{
    margin-top: 4px;
    margin-bottom: 4px;
    font-weight: 400;
font-size: 12px;
line-height: 16px;
  }
`