import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonLabel, IonLoading, IonModal, IonPage, IonRow, IonSelect, IonSelectOption, IonToast } from '@ionic/react'
import { chevronBackOutline, warningOutline } from 'ionicons/icons'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import { RouteState } from '../context/Context'
import { manualEmployee, villeList } from '../services/services'
const ManualRegister:React.FC = () => {
  const {state}=RouteState()
  const [showModal,setShowModal]=useState(false)
  const [loading,setLoading]=useState(false)
  const[showToast,setShowToast]=useState(false)
  const [cities,setCities]=useState<any>([])
  const[city,setCity]=useState("")
  const [idCity,setIdCity]=useState("")
  const {langState:language,langDispatch:{setLanguage,translate}}=RouteState()
  const [user,setUser]=useState({
    name:"",
      phone:"",
      id:"",
      street:"",
      postalCode:"",
      ville:"",
      idCity:"",
      idRoute:localStorage.getItem('idRoute')
  })
    const initialEmployee={
      name:"",
      phone:"",
      id:"",
      street:"",
      postalCode:"",
      ville:"",
      idCity:"",
      idRoute:localStorage.getItem('idRoute')
    }
    const handleChange=(e:any)=>{
      formik.handleChange(e)
      if(cities.length>0)
      console.log(cities.filter((company:any)=>company.idCity===e.detail.value)[0].name)
      setCity(cities.filter((company:any)=>company.idCity===e.detail.value)[0].name)
      setIdCity(e.detail.value)
    }
    const modalRef = useRef<HTMLIonModalElement>(null);
    useLayoutEffect(
      () => () => {
        // hack to fix
        modalRef.current?.dismiss();
        setShowModal(false)
      },
      []
    );
    useEffect(() => {
      const getCities= async ()=>{
        setLoading(true)
        const resp= await villeList()
        if(!resp.error){
          setLoading(false)
          setCities(resp)
        }
      }
    getCities()
      
    }, [])
    const formik=useFormik({
      initialValues:initialEmployee,
      validationSchema:Yup.object().shape({
        name:Yup.string().required(translate("nameRequired")).matches(/^[a-zA-ZÀ-ÿ-. ]*$/,translate("nameCharacters")),
        phone:Yup.number().integer(translate("phoneValidation")).required(translate("phoneRequired")),
        id:Yup.number().integer(translate("streetNumberValidation")),
        street:Yup.string().matches(/^[a-zA-ZÀ-ÿ-0-9\- ]*$/,translate("streetValidation")),
        postalCode: Yup.string().matches(/[A-Za-z0-9-]+/,translate("postalCodeErrorMessage")),
        ville: Yup.string().matches(/^[a-zA-ZÀ-ÿ-0-9\- ]*$/,"Street must contain only letters and numbers"),
      }),
      onSubmit:async (values)=>{
        console.log(state)
        setUser({
          name:values.name,
      phone:values.phone,
      id:values.id,
      street:values.street,
      postalCode:values.postalCode,
      ville:city,
      idCity:idCity,
      idRoute:values.idRoute
        })
        console.log(user)
        setShowModal(true)
        

      }
    })
    const history= useHistory()
    const createEmployee= async()=>{
      console.log(state)
      console.log(user)
      setShowModal(false)
      
      setLoading(true)
      modalRef.current?.dismiss();
      const resp= await manualEmployee(user)
        if(resp.id!=='0'){
          setLoading(false)
          setShowModal(false)
          
          console.log(showModal)
          history.push('/tab2')
        }
        else{
          setLoading(false)
          setShowModal(false)
          setShowToast(true)
          }
    }
    const clearRegister=()=>{
      modalRef.current?.dismiss();
      setUser({
        name:"",
      phone:"",
      id:"",
      street:"",
      postalCode:"",
      ville:"",
      idCity:"",
      idRoute:state.idRoute
      })
      formik.values=initialEmployee
      setShowModal(false)
    }
  return (
    <IonPage>
      {loading?<IonLoading isOpen={loading}></IonLoading>:<>
        <IonHeader className="ion-no-border ion-padding">
        <IonButton
          fill="clear"
          style={{"--color":'var(--ion-color-secondary)' }}
          
          onClick={()=>{history.goBack()}}
        >
          <IonIcon
            icon={chevronBackOutline}
            slot="start"
            size="small"
          ></IonIcon>
          {translate('back')}
        </IonButton>
      </IonHeader>
      <IonContent>
        
        <PageContainer>
            <h4>{translate('manualRegister')}</h4>
            <FormCard>
              <form onSubmit={(e)=>e.preventDefault()} autoComplete='nope'></form>
              <IonLabel className='error__label'>{translate('requiredField')}*</IonLabel>
            <p>{translate('employeeNameRegister')}<span className='required'>*</span></p>
            <CustomInput placeholder={`${translate("example")}Alexandre Dumas`}
            name="name"
            type="text"
            onIonChange={formik.handleChange}
            onIonBlur={formik.handleBlur}
            value={formik.values.name}
            autoCapitalize="words"
            ></CustomInput>
            {formik.touched.name && formik.errors.name && <IonLabel className="error__label">{formik.errors.name}</IonLabel>}
            <p>{translate('phone')}<span className='required'>*</span></p>
            <CustomInput placeholder={`${translate("example")}514-123-4567`}
            name="phone"
            type="text"
            onIonChange={formik.handleChange}
            onIonBlur={formik.handleBlur}
            value={formik.values.phone}></CustomInput>
            {formik.touched.phone && formik.errors.phone && <IonLabel className="error__label">{formik.errors.phone}</IonLabel>}
            <p>{translate('address')}</p> 
            <IonGrid className='ion-no-margin ion-no-padding'>
                <IonRow>
                    <IonCol>
                       <IonLabel >{translate('streetNumber')}</IonLabel>
                        <CustomInput placeholder={`${translate("example")}1433`} style={{'width': '116px'}}
                        name="id"
                        type="text"
                        onIonChange={formik.handleChange}
                        onIonBlur={formik.handleBlur}
                        value={formik.values.id}
                        ></CustomInput> 
                    </IonCol>
                    <IonCol>
                        <IonLabel>
                            {translate('street')}
                        </IonLabel>
                        <CustomInput placeholder={`${translate("example")}Le Caron`}
                        name="street"
                        type="text"
                        autocapitalize='words'
                        onIonChange={formik.handleChange}
                        onIonBlur={formik.handleBlur}
                        value={formik.values.street}
                        ></CustomInput>
                    </IonCol>
                    {formik.touched.id && formik.errors.id && <IonLabel className="error__label">{formik.errors.id}</IonLabel>}
                    {formik.touched.street && formik.errors.street && <IonLabel className="error__label ion-margin-horizontal">{formik.errors.street}</IonLabel>}
                </IonRow>
                <IonRow>
                    <IonCol>
                    <IonLabel >{translate('codePostal')}</IonLabel>
                        <CustomInput placeholder={`${translate("example")}H4E-1J9`} style={{'width': '116px'}}
                        name="postalCode"
                        type="text"
                        autoCapitalize='characters'
                        onIonChange={formik.handleChange}
                        onIonBlur={formik.handleBlur}
                        value={formik.values.postalCode}
                        ></CustomInput>{formik.touched.postalCode && formik.errors.postalCode && <IonLabel className="error__label">{formik.errors.postalCode}</IonLabel>}
                    </IonCol>
                    <IonCol>
                    <IonLabel >{translate('city')}</IonLabel>
                        <CustomSelect placeholder='Montreal' style={{'width': '116px'}}
                        name="ville"
                        interface='popover'
                        onIonChange={e=>{handleChange(e)}}
                        onIonBlur={formik.handleBlur}
                        value={formik.values.ville}
                        >
                        {cities.length>0 && cities.map((city:any)=>(
                          <IonSelectOption value={city.idCity} key={city.idCity}>{city.name}</IonSelectOption>
                        ))}  
                        </CustomSelect>{formik.touched.ville && formik.errors.ville && <IonLabel className="error__label">{formik.errors.ville}</IonLabel>}
                    </IonCol>
                    
                </IonRow>
            </IonGrid>
            <ButtonContainer>
                
                <IonButton onClick={()=>{formik.handleSubmit()}} className="button__action">{translate('continue')}</IonButton>
                <IonButton fill='outline'  className='button__action' type='reset'>{translate('cancel')}</IonButton>
            </ButtonContainer>
          </FormCard>
          
        </PageContainer>
        
        <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={translate("errorEmployeeNotSaved")}
        icon={warningOutline}
        position="bottom"
        duration={900}
      />
    {showModal && 
    <div>
    <IonModal isOpen={showModal} ref={modalRef} >
      
      <IonHeader className="ion-no-border ion-padding" style={{'backgroundColor':'var(--ion-background-color)'}} color='background-color'>
    <IonButton
        fill="clear"
        style={{"--color":'var(--ion-color-secondary)' }}
        
        onClick={()=>{setShowModal(false)}}
      >
        <IonIcon
          icon={chevronBackOutline}
          slot="start"
          size="small"
        ></IonIcon>
        {translate("back")}
      </IonButton>
    </IonHeader>
    <IonContent>
      <PageContainer>
        <h4>{translate("checkInfo")}</h4>
        <InfoContainer>
          <p>{translate("employeeName")}: {user.name}</p>

          <p>{translate("phone")}: {user.phone}</p>

          <p>{translate("address")}: {user.street} {user.id}, {user.postalCode} {user.ville}</p>
        </InfoContainer>
        
          <p className='confirm'>{translate("checkInfoQuestion")}</p>
          <ButtonContainer>
          <IonButton className="button__action" onClick={createEmployee}>{translate("continue")}</IonButton>
              <IonButton fill='outline' className="button__action" onClick={()=>clearRegister()}>{translate("cancel")}</IonButton>
              
          </ButtonContainer>
        
        
      </PageContainer>
      </IonContent>


      </IonModal></div>} 
      </IonContent>
      
    </>}
    
    </IonPage>
  )
}

export default ManualRegister
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding-inline:  32px;
  h4{
    font-weight: 400;
font-size: 24px;
line-height: 32px;
align-self: center;
margin-bottom: 24px;
  }
  ion-label{
    font-size:12px;
  }
`;
const CustomInput = styled(IonInput)`
  background: white;
  border: 1px solid #eff0f7;
  --padding-start: 16px;
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 46px;
  
`;

const ButtonContainer = styled.div`
    display: flex;
    padding: 24px 16px;
   
    width: 100%;
    align-items: center;
    justify-content: space-between;
`

const FormCard=styled.div`
  background: #C0CDD9;
  
  border-radius: 24px;
  width:100%;
  padding: 16px;
  p{
    font-weight: 500;
font-size: 14px;
line-height: 20px;
margin-bottom: 6px;
  }

`

const InfoContainer = styled.div`
  width: 90%;
  background: white;
  align-self:center;
  box-shadow: 0px 0.5px 1px 2px rgba(0, 0, 0, 0.11);
border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  justify-content: center;
  padding: 24px 16px;
  p{
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;
const CustomSelect = styled(IonSelect)`
  background: white;
  border: 1px solid #eff0f7;
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 46px;
  width: 100% !important;
`;