import { IonButton, IonContent, IonHeader, IonIcon, IonInput, IonLabel, IonPage, IonToast } from '@ionic/react'
import { useFormik } from 'formik'
import { chevronBackOutline, mailOutline, warningOutline } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import * as Yup from'yup'
import { RouteState } from '../context/Context'
import { route, shareEmployees } from '../services/services'
const ShareWorkers: React.FC = () => {
    const history= useHistory()
    const {id}= useParams<any>()
    const [showAlert,setShowAlert]=useState(false)
    const [currentRoute, setCurrentRoute]=useState<any>()
    const [loading,setLoading]=useState(false)
    const {langState:language,langDispatch:{setLanguage,translate}}=RouteState()
    const initialUser={
        idRoute:id,
        mail: "",
        
    }
    useEffect(() => {
      
      const routeDetails= async()=>{
        setLoading(true)
        const resp= await route(id)
        if(!resp.error){
          console.log(resp)
          setCurrentRoute(resp[0])
          setLoading(false)
        }
      }
      routeDetails()
    }, [])
    
    const formik = useFormik({
        initialValues: initialUser,
        validationSchema: Yup.object().shape({
      
            mail: Yup.string().email(translate("mailValidation")).required(translate("mailRequired")),

            
        }),
        onSubmit: async(values)=>{
          console.log('onSubmit')
          const resp= await shareEmployees(values)
          if(resp.res!=="error"){
            history.push('/mailSuccess')
          }
          else{
            setShowAlert(true)

          }
        }
    })
  return (
    <IonPage>
        
        <IonHeader className="ion-no-border ion-padding-top ion-padding-horizontal">
        <IonButton
          fill="clear"
          style={{"--color":'var(--ion-color-secondary)' }}
          
          onClick={()=>{history.goBack()}}
        >
          <IonIcon
            icon={chevronBackOutline}
            slot="start"
            size="small"
          ></IonIcon>
          {translate('back')}
        </IonButton>
      </IonHeader>
        <IonContent>
        <PageContainer>
                <h4>Share Workers</h4>

                {currentRoute && <HistoryCard>
            <p>{translate('company')} : {currentRoute?.companyName}</p>
            <p>
              {translate('NroUnit')} : <strong>{currentRoute?.vehicleNumber}</strong>
            </p>

            <p>{translate('boardingPoint')}:</p>
            <p>
              <strong>{currentRoute?.boardingPoint}</strong>
            </p>
            <p>Date: {new Date(currentRoute.route_date.replace(/-/g, "/")).toLocaleString('es')}</p>
            <div>
              <p className="margin__right">{translate('route')}:</p>
              <p className="route__type"> {currentRoute?.type}</p>
            </div>
            <p>{translate('driver')}: {localStorage.getItem('name')}</p>
            
          </HistoryCard>}
          <FormCard>
                <form onSubmit={(e) => {
              e.preventDefault();
            }}
            autoComplete="nope"
            className='form__align'>
                <p>{translate('mail')}</p>
                <CustomInput placeholder='charles@gmail.com'
                name="mail"
                type="text"
                onIonChange={formik.handleChange}
                onIonBlur={formik.handleBlur}
                value={formik.values.mail}
                >
                    
                </CustomInput>
                {formik.touched.mail && formik.errors.mail && <IonLabel className="error__label">{formik.errors.mail}</IonLabel>}
   
                <BottomDiv>
          
                <IonButton onClick={()=>{formik.handleSubmit()}} className="button__action">{translate('continue')}</IonButton>
                <IonButton fill='outline'  className='button__action' type='reset'>{translate('cancel')}</IonButton>
                </BottomDiv>
                </form>
              </FormCard>
            </PageContainer>
        </IonContent>
        <IonToast
        isOpen={showAlert}
        onDidDismiss={()=>setShowAlert(false)}
        message={translate("errorMailNotSent")}
        icon={warningOutline}
        position="bottom"
        duration={900}
      />
    </IonPage>
  )
}

export default ShareWorkers

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 12px 32px;
  h4{
    font-weight: 400;
font-size: 24px;
line-height: 32px;
align-self: center;
margin-bottom: 24px;
    
  }
  ion-label{
    font-size:12px;
  }
`;
const CustomInput = styled(IonInput)`
  background: white;
  border: 1px solid #eff0f7;
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 46px;

`;

const BottomDiv = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    p{
        margin-bottom: 0;
        font-size: 14px;
    }
    align-self: center;
`
const FormCard=styled.div`
  background: #C0CDD9;
  box-shadow: 0px 0.5px 1px 2px rgba(0, 0, 0, 0.11);
  border-radius: 24px;
  width:100%;
  padding: 16px;
  p{
    font-weight: 500;
font-size: 14px;
line-height: 20px;
margin-bottom: 6px;
  }

`

const HistoryCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 0.5px 1px 2px rgba(0, 0, 0, 0.11);
border-radius: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  align-items: flex-start;
  justify-content: center;
  p {
    margin-top: 5px;
    
    font-weight: 500;
    font-size: 14px;
    line-height: 11px;
  }


  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 5px;

    padding-left: 0;
  }
  margin-bottom: 16px;
`;
