import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonToast,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { chevronBackOutline, warningOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import {useFormik} from 'formik'
import * as Yup from 'yup'
import { RouteState } from "../context/Context";
import { boardingPointList, companiesList, newRoute } from "../services/services";
const SelectRoute: React.FC = () => {
  const [companies,setCompanies]=useState<any>([])
  const [showToast,setShowToast]=useState(false)
  const [loading,setLoading]=useState(false)
  const[boardingPoints,setBoardingPoints]=useState<any>([])
  const [isReturn,setIsReturn]=useState(false)
  const {userState}=RouteState()
  const [company,setCompany]=useState("")
    const history= useHistory()
    const {
      state:{idRoute,enterprise,vehicleNumber,startingPoint,routeType},
      dispatch
    } = RouteState()
    const {langState:language,langDispatch:{setLanguage,translate}}=RouteState()
    const initialRoute={
      enterprise:"",
      vehicleNumber:"",
      startingPoint:"",
      routeType:"",
      idDriver:localStorage.getItem("idDriver")
    }
    useEffect(() => {
      const getCompanies= async ()=>{
        setLoading(true)
        const resp= await companiesList()
        if(!resp.error){
          setLoading(false)
          setCompanies(resp)
        }
      }
    getCompanies()
    const getBoardingPoints= async ()=>{
      setLoading(true)
      const resp= await boardingPointList()
      console.log(resp)
      if(!resp.error){
        setLoading(false)
        setBoardingPoints(resp)
      }
    }
  getBoardingPoints()
    }, [])
    
    const formik=useFormik({
      initialValues:initialRoute,
      validationSchema: Yup.object().shape({
        enterprise: Yup.string().required(translate("companyRequired")),
        vehicleNumber:Yup.number().integer(translate("vehicleNumberInteger")).required(translate("vehicleNumberRequired")),
        startingPoint:Yup.string().required(translate("boardingPointRequired")),
        routeType: Yup.string().required(translate("routeTypeRequired"))
      }),
      
      onSubmit: async(values)=>{
        console.log(new Date().toLocaleTimeString())
        setLoading(true)
        const resp = await newRoute(values)
        console.log(resp)
        if(resp.length>0){
          setLoading(false)
          dispatch({
          type:"ADD_ROUTE",
          payload: {
            idRoute: resp[0].idRoute,
            enterprise:company,
      vehicleNumber:values.vehicleNumber,
      startingPoint:resp[0].boardingPoint,
      routeType:resp[0].type,
      date: new Date().toLocaleString()
          }
        })
        localStorage.setItem("idRoute",resp[0].idRoute)
        history.push('/tab2')
        }
        else{
          setLoading(false)
          setShowToast(true)
        }
        
      }
    })
    const handleChange=(e:any)=>{
      formik.handleChange(e)
      if(e.detail.value==='retour'){
        setIsReturn(true)
        console.log(e.detail.value)
      }
      else{
        setIsReturn(false)
      }
      
    }
    const getCompanyName=(e:any)=>{
      formik.handleChange(e)
      if(companies.length>0){
        console.log(companies.filter((company:any)=>company.idCompany===e.detail.value)[0].name)
        setCompany(companies.filter((company:any)=>company.idCompany===e.detail.value)[0].name)
      }
    }
  return (
    <IonPage>
      {loading ? <IonLoading isOpen={loading}></IonLoading>:<>
      <IonHeader className="ion-no-border ion-padding-top">
        <IonButton
          fill="clear"
          style={{"--color":'var(--ion-color-secondary)' }}
          
          onClick={()=>{history.goBack()}}
        >
          <IonIcon
            icon={chevronBackOutline}
            slot="start"
            size="small"
          ></IonIcon>
          {translate('back')}
        </IonButton>
      </IonHeader>
      <IonContent>
        <PageContainer>
          <h4>{translate('newRoute')}</h4>
          <FormCard>
          <form onSubmit={(e) => {
              e.preventDefault();
            }}
            autoComplete="nope"
            className="form__align">
               <IonRadioGroup 
                onIonChange={e=>{handleChange(e)}}
                name="routeType"
                value={formik.values.routeType}>
                
          
          <p>{translate(routeType)}</p>
          <RadioContainer>
          <IonItem lines='none' color='transparent'>
          <IonLabel className="ion-margin-horizontal">{translate('go')}</IonLabel>
          <IonRadio value="aller" style={{'--color': 'var(--ion-color-primary)'}} />
          
       
          </IonItem>
          <IonItem lines='none' color='transparent'>
        <IonLabel className="ion-margin-horizontal">{translate('return')}</IonLabel>
          <IonRadio value="retour" style={{'--color': 'var(--ion-color-primary)'}}/>
          
        </IonItem>
        </RadioContainer>
          </IonRadioGroup>
          {formik.touched.routeType && formik.errors.routeType && <IonLabel className="error__label">{formik.errors.routeType}</IonLabel>}
          <p>{translate('companyName')} </p>
          <CustomSelect
          name="enterprise"
          placeholder='Exceldor INC'
          interface="popover"
          
                onIonChange={formik.handleChange}
                onIonBlur={formik.handleBlur}
                value={formik.values.enterprise}>
                  {companies.length>0 && companies.map((company:any)=>(
                    <IonSelectOption value={company.idCompany} key={company.idCompany}>{company.name}</IonSelectOption>
                  ))}
                    
                </CustomSelect>
                {formik.touched.enterprise && formik.errors.enterprise && <IonLabel className="error__label">{formik.errors.enterprise}</IonLabel>}
          <p>{translate('vehicleNumber')}</p>
          <CustomInput type='text' maxlength={5} style={{'width': '30%'}}
          name="vehicleNumber"
          placeholder={`${translate("example")}130`}
          onIonChange={formik.handleChange}
          onIonBlur={formik.handleBlur}
          value={formik.values.vehicleNumber}></CustomInput>
          {formik.touched.vehicleNumber && formik.errors.vehicleNumber && <IonLabel className="error__label">{formik.errors.vehicleNumber}</IonLabel>}
          <p>{translate('boardingPoint')}</p>
          <CustomSelect 
          interface="popover"
          name="startingPoint"
          onIonChange={(e)=>getCompanyName(e)}
          onIonBlur={formik.handleBlur}
          value={formik.values.startingPoint}
          defaultChecked
          placeholder="Metro St-Michel">
            {boardingPoints.length>0 && isReturn?
            <IonSelectOption value={boardingPoints[0].idBoardingPoint} >{boardingPoints[0].name}</IonSelectOption>
            : boardingPoints.map((boardingPoint:any)=>(
              <IonSelectOption value={boardingPoint.idBoardingPoint} key={boardingPoint.idBoardingPoint} >{boardingPoint.name}</IonSelectOption>
            ))}
          </CustomSelect>
          {formik.touched.startingPoint && formik.errors.startingPoint && <IonLabel className="error__label ">{formik.errors.startingPoint}</IonLabel>}
          <ButtonContainer>
          <IonButton onClick={()=>{formik.handleSubmit()}} className="button__action">{translate('continue')}</IonButton>
          <IonButton fill='outline'  className='button__action' type="reset">{translate('cancel')}</IonButton>
          </ButtonContainer>
        </form>
        </FormCard>
        </PageContainer>
        <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={translate("errorRouteNotSaved")}
        icon={warningOutline}
        position="bottom"
        duration={900}
      />
        </IonContent>
      </>}
    </IonPage>
  );
};

export default SelectRoute;
const ButtonContainer = styled.div`
    display: flex;
    padding: 24px 6px;
   
    width: 100%;
    align-items: center;
    justify-content: space-between;
`

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 6px 32px;
  h4{
    font-weight: 400;
font-size: 24px;
line-height: 32px;
align-self: center;
margin-bottom: 24px;
  }
`;
const CustomInput = styled(IonInput)`
  background: white;
  border: 1px solid #eff0f7;
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 46px;
  padding-left: 18px;
  --padding-start: 18px;
`;
const CustomSelect = styled(IonSelect)`
  background: white;
  border: 1px solid #eff0f7;
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 46px;
  width: 80%;
`;
const RadioContainer= styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

`

const FormCard=styled.div`
  background: #C0CDD9;
 
  border-radius: 24px;
  width:100%;
  padding: 16px;
  p{
    font-weight: 500;
font-size: 14px;
line-height: 20px;
margin-bottom: 6px;
  }

`