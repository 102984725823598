import { IonAlert, IonButton, IonContent, IonHeader, IonIcon, IonModal, IonPage, IonPopover, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import styled from 'styled-components';
import './Tab3.css';
import { useEffect, useState } from 'react';
import { RouteState } from '../context/Context';
import { useHistory } from 'react-router';

const Tab3: React.FC = () => {
  const history=useHistory()
  const [showAlert, setShowAlert]=useState<boolean>(false)
  const {userState,userDispatch}=RouteState()
  const {langState:language,langDispatch:{setLanguage,translate}}=RouteState()
  const logOut=()=>{
    setShowAlert(false)
    localStorage.removeItem('idDriver')
    localStorage.removeItem('idRoute')
    localStorage.removeItem('name')
    localStorage.removeItem('email')
    localStorage.removeItem('driverNumber')
    localStorage.removeItem('Auth')

    history.replace('/principal')
  }
  useEffect(() => {
    // Verifica si el localStorage tiene la clave "name"
    const name = localStorage.getItem('name');

    // Si está vacío o no existe, redirige al login
    if (!name) {
      history.replace('/principal'); // Cambia '/login' por la ruta de tu página de inicio de sesión
    }
  }, [history]);
  return (
    <IonPage>
      
      <IonContent fullscreen>
       
        <PageContainer>
          <IonIcon icon={`${process.env.PUBLIC_URL}/assets/icon/user.svg`}></IonIcon>
          <h3>{userState.name}</h3>
          <InfoContainer>
            <p>{translate('name')}: {localStorage.getItem('name')}</p>
            <p>{translate("mail")}: {localStorage.getItem('email')}</p>
            <p>{translate("password")}: ******** </p>
            <p>{translate("driverNumber")}: {localStorage.getItem('driverNumber')}</p>

          </InfoContainer>
          
         <IonButton className='button__action' onClick={()=>setShowAlert(true)}>{translate("logOut")}</IonButton>
          
         <a className='password__link' onClick={()=>history.push('/changePassword')}>{translate('changePassword')}</a>
        </PageContainer>
      </IonContent>
      <IonModal  isOpen={showAlert}  id='example-modal' onDidDismiss={()=>setShowAlert(false)}>
        
        <AlertContainer>
          <IonIcon icon={`${process.env.PUBLIC_URL}/assets/icon/alert.svg`} color="secondary" size='large'></IonIcon>
          <p>{translate('confirmLogOut')}</p>
          <ButtonDiv>
          <IonButton className='button__action' onClick={logOut}>
          {translate('logOut')}
            </IonButton>
            <IonButton fill='outline' className='button__action' onClick={()=>setShowAlert(false)}>
                {translate('cancel')}
            </IonButton>
            
          </ButtonDiv>
        </AlertContainer>
        
     
    </IonModal>
    </IonPage>
  );
};

export default Tab3;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 32px;
  h4 {
    font-weight: bold;
    align-self: center;
    
  }
  ion-icon{
    font-weight: bold !important;
    font-size: 42px !important;
  }
  h5{
    font-weight: bold;
    margin-bottom: 42px;
    margin-top: 6px;
  }
  ion-label {
    font-size: 12px;
  }
  height: 100%;
  position: relative;
`;
const BottomDiv = styled.div`
    position: absolute;
    bottom: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    p{
        margin-bottom: 0;
        font-size: 14px;
    }
`
const AlertContainer=styled.div`
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  width:90%;
  p{
    color: black;
    text-align: center;
  }
  
`
const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:90%;


`
const InfoContainer = styled.div`
  width: 90%;
  background: white;
  box-shadow: 0px 0.5px 1px 2px rgba(0, 0, 0, 0.11);
border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 32px;
  margin-bottom: 32px;
  justify-content: center;
  padding: 12px 16px;
  p{
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;