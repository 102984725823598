import { IonButton, IonContent, IonHeader, IonIcon, IonInput, IonLabel, IonLoading, IonPage, IonToast } from '@ionic/react';
import { eyeOutline, mailOutline,eyeOffOutline, chevronBackOutline, warningOutline } from 'ionicons/icons';
import React, { useState } from 'react'
import styled from 'styled-components';
import {useFormik} from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router';
import { login, routes } from '../services/services';
import { RouteState } from '../context/Context';
import { useTranslation } from 'react-i18next';

const Login:React.FC = () => {
  const{langDispatch:{translate}}=RouteState()
  const [showPassword,setShowPassword]=useState(false)
  const [showToast,setShowToast]=useState(false)
  const [loading,setLoading]=useState(false)
  const { t } = useTranslation();
  const {userState:{
    name,email,password,state,driverNumber
  },userDispatch,dispatch}= RouteState()
  const history= useHistory()
  const initialUser={
    mail:"",
    password:""
  }
  const formik=useFormik({
    initialValues:initialUser,
    validationSchema: Yup.object().shape({
      mail: Yup.string().email(translate("mailValidation")).required(translate("mailRequired")),
      password: Yup.string().required(translate("passwordRequired")),
    }),
    onSubmit: async(values)=>{
      setLoading(true)
      const resp= await login(values)

      if(!resp.error ){
        const loggedUser={
          id:resp[0].idDriver,
          name:resp[0].name,
                email:resp[0].email,
                password:resp[0].password,
                state:resp[0].state,
                driverNumber:resp[0].driverNumber,
        }
        userDispatch({
          type:"LOGIN",
          payload: loggedUser
        })
        localStorage.setItem("Auth","true")
        localStorage.setItem("idDriver",resp[0].idDriver)
        localStorage.setItem("name",resp[0].name)
        localStorage.setItem('email',resp[0].email)
        localStorage.setItem('driverNumber',resp[0].driverNumber)
        const routesList=await routes(localStorage.getItem('idDriver'))
        if(!routesList.error){
          const activeRoutes=routesList.filter((route:any)=>route.state==='1')
          if(activeRoutes.length>0){
            console.log("activeRoutes")
            dispatch({
              type:"ADD_ROUTE",
              payload: {
                idRoute: activeRoutes[activeRoutes.length-1].idRoute,
                enterprise:activeRoutes[activeRoutes.length-1].companyName,
          vehicleNumber:activeRoutes[activeRoutes.length-1].vehicleNumber,
          startingPoint:activeRoutes[activeRoutes.length-1].boardingPoint,
          routeType:activeRoutes[activeRoutes.length-1].type,
          date: activeRoutes[activeRoutes.length-1].route_date
              }
            })
            localStorage.setItem('idRoute',activeRoutes[activeRoutes.length-1].idRoute)
            console.log("Aca si se esta guardando: ", localStorage.getItem('idRoute'));
          }
          
        }
        setLoading(false)
        if(resp[0].state==='1'){
          history.push('/changePassword')
        }
        else{
          history.push('/tab2')
        }
      }
      else{
        setLoading(false)
        setShowToast(true)

      }
      
    }
  })
  return (
    <IonPage>
      {loading ? <IonLoading isOpen={loading}></IonLoading>:
      <>
      <IonHeader className="ion-no-border ion-padding-top ion-padding-horizontal">
        <IonButton
          fill="clear"
          style={{"--color":'var(--ion-color-secondary)' }}
          
          onClick={()=>{history.goBack()}}
        >
          <IonIcon
            icon={chevronBackOutline}
            slot="start"
            size="small"
          ></IonIcon>
          {translate('back')}
        </IonButton>
      </IonHeader>
        <IonContent>
            <PageContainer>
                <h4>{translate("logIn")}</h4>
                <FormCard>
                <form onSubmit={(e) => {
              e.preventDefault();
            }}
            autoComplete="nope"
            className='form__align'>
            <p>{translate('mail')}</p>
                <CustomInput placeholder='charles@gmail.com'
                name="mail"
                type="text"
                onIonChange={formik.handleChange}
                onIonBlur={formik.handleBlur}
                value={formik.values.mail}>
                    <IonIcon icon={mailOutline} slot='start' className='ion-margin-horizontal' color='medium' size='small'>

                    </IonIcon>
                </CustomInput>
                {formik.touched.mail && formik.errors.mail && <IonLabel className="error__label">{formik.errors.mail}</IonLabel>}
                <p>{translate('password')}</p>
                <CustomInput placeholder='&bull;&bull;&bull;&bull;&bull;&bull;'
                type={showPassword? "text":"password"}
                name='password'
                onIonChange={formik.handleChange}
                onIonBlur={formik.handleBlur}
                value={formik.values.password}>
                    <IonIcon onClick={()=>setShowPassword(!showPassword)} icon={showPassword? eyeOffOutline :eyeOutline} slot='start' className='ion-margin-horizontal' color='medium' size='small'>

                    </IonIcon>
                </CustomInput>
                {formik.touched.password && formik.errors.password && <IonLabel className="error__label">{formik.errors.password}</IonLabel>}
               
                
                    <IonButton className='button__action ion-margin-top' onClick={()=>formik.handleSubmit()} style={{'alignSelf':'center'}}>
                    {translate('logIn')}
                    </IonButton>
                 </form>
                </FormCard>
                <a className='password__link' onClick={()=>history.push('/forgotPassword')}>{translate('forgotPassword')}</a>
            </PageContainer>
            <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={translate("errorLoginAlert")}
        icon={warningOutline}
        position="bottom"
        duration={900}
      />
        </IonContent>
    </>}
    </IonPage>
  )
}

export default Login

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 12px 32px;
  h4{
    font-weight: 400;
font-size: 24px;
line-height: 32px;
    align-self: center;
    margin-top: 0;
    margin-bottom: 24px;
  }
  ion-label{
    font-size:12px;
  }
  a{
    align-self: center;
    margin-top: 24px;
  }
`;
const CustomInput = styled(IonInput)`
  background: white;
  border: 1px solid #eff0f7;
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 46px;

`;

const BottomDiv = styled.div`
    position: absolute;
    bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    p{
        margin-bottom: 0;
        font-size: 14px;
    }
`
const FormCard=styled.div`
  background: #C0CDD9;
  
  border-radius: 24px;
  width:100%;
  padding: 16px;
  p{
    font-weight: 500;
font-size: 14px;
line-height: 20px;
margin-bottom: 6px;
  }

`