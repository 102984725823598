import { IonButton, IonContent, IonHeader, IonIcon, IonInput, IonLabel, IonPage, IonToast } from '@ionic/react'
import { useFormik } from 'formik'
import { chevronBackOutline, mailOutline, warningOutline } from 'ionicons/icons'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import * as Yup from 'yup'
import { RouteState } from '../context/Context'
import { resetPassword } from '../services/services'
const ForgotPassword:React.FC = () => {
  const history= useHistory()
  const[showAlert,setShowAlert]=useState(false)
  const[alertMessage,setAlertMessage]=useState("")
  const {langState:language,langDispatch:{setLanguage,translate}}=RouteState()
  const close=()=>{
    setShowAlert(false)
    history.push('/login')
  }
  const initialUser={
    mail:"",
    
  }
  const formik=useFormik({
    initialValues:initialUser,
    validationSchema: Yup.object().shape({
      mail: Yup.string().email(translate("mailValidation")).required(translate("mailRequired")),
     
    }),
    onSubmit: async(values)=>{
      const resp= await resetPassword(values)
      if(resp.res!=="error"){
        setAlertMessage(translate("passwordChangedAlert"))
      }
      else{
        setAlertMessage(translate("errorPasswordChangedAlert"))
    }
    setShowAlert(true)
    }
    
  })
  return (
    <IonPage>
            <IonHeader className="ion-no-border ion-padding-top ion-padding-horizontal">
        <IonButton
          fill="clear"
          style={{"--color":'var(--ion-color-secondary)' }}
          
          onClick={()=>{history.goBack()}}
        >
          <IonIcon
            icon={chevronBackOutline}
            slot="start"
            size="small"
          ></IonIcon>
          {translate('back')}
        </IonButton>
      </IonHeader>
        <IonContent>
            <PageContainer>
                <h4>{translate('forgotPassEmail')}</h4>
                <FormCard>
                <form onSubmit={(e) => {
              e.preventDefault();
            }}
            autoComplete="nope"
            className='form__align'>
            <p>Courriel</p>
                <CustomInput placeholder='charles@gmail.com'
                name="mail"
                type="text"
                onIonChange={formik.handleChange}
                onIonBlur={formik.handleBlur}
                value={formik.values.mail}>
                    <IonIcon icon={mailOutline} slot='start' className='ion-margin-horizontal' color='medium' size='small'>

                    </IonIcon>
                </CustomInput>
                {formik.touched.mail && formik.errors.mail && <IonLabel className="error__label">{formik.errors.mail}</IonLabel>}
                
                
                    <IonButton className='button__action ion-margin-vertical' onClick={()=>formik.handleSubmit()}>
                    {translate('send')}
                    </IonButton>
                 </form>
                </FormCard>
                
            </PageContainer>
            <IonToast
        isOpen={showAlert}
        onDidDismiss={close}
        message={alertMessage}
        icon={warningOutline}
        position="bottom"
        duration={900}
      />
        </IonContent>


    </IonPage>
  )
}

export default ForgotPassword
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 12px 32px;
  h4{
    font-weight: 400;
font-size: 24px;
line-height: 32px;
    align-self: center;
    margin-top: 0;
    margin-bottom: 24px;
  }
  ion-label{
    font-size:12px;
  }
  a{
    align-self: center;
    margin-top: 24px;
  }
`;
const CustomInput = styled(IonInput)`
  background: white;
  border: 1px solid #eff0f7;
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 46px;

`;

const BottomDiv = styled.div`
    position: absolute;
    bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    p{
        margin-bottom: 0;
        font-size: 14px;
    }
`
const FormCard=styled.div`
  background: #C0CDD9;
  box-shadow: 0px 0.5px 1px 2px rgba(0, 0, 0, 0.11);
  border-radius: 24px;
  width:100%;
  padding: 16px;
  p{
    font-weight: 500;
font-size: 14px;
line-height: 20px;
margin-bottom: 6px;
  }

`