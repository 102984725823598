import axios from 'axios'

const url="https://otikaapp.com/services"
export const resetPassword= async(values:any)=>{
  const formData=new FormData()
  formData.append("email",values.mail)
    return await axios.post(
        url+"/forgot_password.php",
        formData,
        {
            headers:{
            'Accept': "application/json",
        }
    }
    ).then((response) => {
        return response.data;
      })
      .catch((error: any) => {
        return error;
      });
}
export const shareEmployees= async(values:any)=>{
  const formData=new FormData()
  console.log(values.idRoute, values.mail)
  formData.append("email",values.mail)
  formData.append('idRoute',values.idRoute)
    return await axios.post(
        url+"/send_list.php",
        formData,
        {
            headers:{
            'Accept': "application/json",
        }
    }
    ).then((response) => {
        return response.data;
      })
      .catch((error: any) => {
        return error;
      });
}
export const route= async(id:any)=>{
  const formData=new FormData()
  formData.append("idRoute",id)
    return await axios.post(
        url+"/get_route.php",
        formData,
        {
            headers:{
            'Accept': "application/json",
        }
    }
    ).then((response) => {
        return response.data;
      })
      .catch((error: any) => {
        return error;
      });
}
export const companiesList= async()=>{
    return await axios.get(
        url+"/companies.php ",
        {
            headers:{
            'Accept': "application/json",
        }
    }
    ).then((response) => {
        return response.data;
      })
      .catch((error: any) => {
        return error;
      });
}
export const boardingPointList= async()=>{
    return await axios.get(
        url+"/boarding_points.php ",
        {
            headers:{
            'Accept': "application/json",
        }
    }
    ).then((response) => {
        return response.data;
      })
      .catch((error: any) => {
        return error;
      });
}
export const villeList= async()=>{
    return await axios.get(
        url+"/cities.php ",
        {
            headers:{
            'Accept': "application/json",
        }
    }
    ).then((response) => {
        return response.data;
      })
      .catch((error: any) => {
        return error;
      });
}
export const employeeDetails= async(id:any)=>{
  const formData=new FormData()
  formData.append('code',id)
    return await axios.post(
      url+"/get_employee.php",
      formData,
        {
            headers:{
            'Accept': "application/json",
        }
    }
    ).then((response) => {
        return response.data;
      })
      .catch((error: any) => {
        return error;
      });
}
export const assignEmployee= async(idEmployee:any,idRoute:any)=>{
  const formData=new FormData()
  let idRouteLocal:any=localStorage.getItem('idRoute')
  formData.append('idEmployee',idEmployee)
  formData.append('idRoute',idRouteLocal)
  console.log("idRoute",idRoute)
    return await axios.post(
      url+"/register_employee_qr.php",
      formData,
        {
            headers:{
            'Accept': "application/json",
            }
    }
    ).then((response) => {
        return response.data;
      })
      .catch((error: any) => {
        return error;
      });
}
export const finishRoute= async(idRoute:any, state:any)=>{
  const formData=new FormData()
  formData.append('state',state)
  formData.append('idRoute',idRoute)
    return await axios.post(
      url+"/update_route.php",
      formData,
        {
            headers:{
            'Accept': "application/json",
            }
    }
    ).then((response) => {
        return response.data;
      })
      .catch((error: any) => {
        return error;
      });
}

export const manualEmployee= async(values:any)=>{
  const formData=new FormData()
  formData.append('name',values.name)
  formData.append('phone',values.phone)
  formData.append('number',values.id)
  formData.append('street',values.street)
  formData.append('postalCode',values.postalCode)
  formData.append('idRoute',values.idRoute)
  formData.append("idCity",values.idCity)
  console.log(values)
  return await axios.post(
    url+"/register_employee.php",formData,
    {
        headers:{
        'Accept': "application/json",
    }
}
).then((response) => {
    console.log(response.data)
    return response.data;
  })
  .catch((error: any) => {
    return error;
  });
}

export const routes= async(id:any)=>{
  const formData=new FormData()
  formData.append('idDriver',id)
  return await axios.post(
    url+"/get_routes.php",
    formData,
    {
        headers:{
          'Accept': "application/json",
    }
}
).then((response) => {
    return response.data;
  })
  .catch((error: any) => {
    return error;
  });
}
export const EmployeesRoute= async(id:any)=>{
  const formData=new FormData()
  formData.append('idRoute',id)
  return await axios.post(
    url+"/get_route_employees.php",
    formData,
    {
        headers:{
          'Accept': "application/json",
    }
}
).then((response) => {
    return response.data;
  })
  .catch((error: any) => {
    return error;
  });
}
export const newRoute= async(values: any)=>{
  const formData=new FormData()
  formData.append('idCompany',values.enterprise)
  formData.append('type',values.routeType)
  formData.append('vehicleNumber',values.vehicleNumber)
  formData.append('boardingPoint',values.startingPoint)
  formData.append('IdDriver',values.idDriver)
  console.log(values)
  return await axios.post(
    url+"/register_route.php",
    formData,
    {headers:{
      "Accept":"application/json"
    }}
    
).then((response) => {
    return response.data;
  })
  .catch((error: any) => {
    return error;
  });
}
export const login= async(values:any)=>{
  const formData = new FormData()
  formData.append("email",values.mail)
  formData.append("password",values.password)
  return await axios.post(
    url+"/login_driver.php",
    formData,
    {
      headers: { 'Accept': 'application/json' }
    
}
).then((response) => {
    return response.data;
  })
  .catch((error: any) => {
    return error;
  });
}
export const changePassword= async(values:any)=>{
  const formData = new FormData()
  formData.append("id",values.id)
  formData.append("password",values.password)
  return await axios.post(
    url+"/update_password_driver.php",
    formData,
    {
      headers: { 'Accept': 'application/json' }
    
}
).then((response) => {
    return response.data;
  })
  .catch((error: any) => {
    return error;
  });
}