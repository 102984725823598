import { IonButton, IonContent, IonHeader, IonIcon, IonInput, IonLabel, IonPage } from '@ionic/react'
import { useFormik } from 'formik'
import { chevronBackOutline, eyeOffOutline, eyeOutline } from 'ionicons/icons'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import * as Yup from 'yup'
import { RouteState } from '../context/Context'
import { changePassword } from '../services/services'
const ChangePassword:React.FC = () => {
    const [showPassword,setShowPassword]=useState(false)
    const [showConfirmPassword,setShowConfirmPassword]=useState(false)
    const {langState:language,langDispatch:{setLanguage,translate}}=RouteState()
  const history= useHistory()
  const initialUser={
    confirmPassword:"",
    password:"",
    id:localStorage.getItem('idDriver')
  }
  const formik=useFormik({
    initialValues:initialUser,
    validationSchema: Yup.object().shape({
      
      password: Yup.string().required(translate("passwordRequired")),
      confirmPassword: Yup.string()
      .required(translate("confirmPasswordRequired"))
      .oneOf([Yup.ref('password')], translate("passwordMatch")),
    }),
    onSubmit: async(values)=>{
        const resp= await changePassword(values)
        if(!resp.error){
            history.push("/tab2")
        }
    }
  })
  return (
    <IonPage>
        <IonHeader className="ion-no-border ion-padding-top ion-padding-horizontal">
        <IonButton
          fill="clear"
          style={{"--color":'var(--ion-color-secondary)' }}
          
          onClick={()=>{history.goBack()}}
        >
          <IonIcon
            icon={chevronBackOutline}
            slot="start"
            size="small"
          ></IonIcon>
          {translate('back')}
        </IonButton>
      </IonHeader>
        <IonContent>
            <PageContainer>
                <h4>{translate('welcome')}</h4>
                <h4>{localStorage.getItem('name')}</h4>
                <h5></h5>
                <FormCard>
                <form onSubmit={(e) => {
              e.preventDefault();
            }}
            autoComplete="nope"
            className='form__align'>
        
                <p>{translate('password')}</p>
                <CustomInput placeholder='&bull;&bull;&bull;&bull;&bull;&bull;'
                type={showPassword? "text":"password"}
                name='password'
                onIonChange={formik.handleChange}
                onIonBlur={formik.handleBlur}
                value={formik.values.password}>
                    <IonIcon onClick={()=>setShowPassword(!showPassword)} icon={showPassword? eyeOffOutline :eyeOutline} slot='start' className='ion-margin-horizontal' color='medium' size='small'>

                    </IonIcon>
                </CustomInput>
                {formik.touched.password && formik.errors.password && <IonLabel className="error__label">{formik.errors.password}</IonLabel>}
               
                
                <p>{translate('newPassword')}</p>
                <CustomInput placeholder='&bull;&bull;&bull;&bull;&bull;&bull;'
                type={showPassword? "text":"password"}
                name='confirmPassword'
                onIonChange={formik.handleChange}
                onIonBlur={formik.handleBlur}
                value={formik.values.confirmPassword}>
                    <IonIcon onClick={()=>setShowConfirmPassword(!showConfirmPassword)} icon={showConfirmPassword? eyeOffOutline :eyeOutline} slot='start' className='ion-margin-horizontal' color='medium' size='small'>

                    </IonIcon>
                </CustomInput>
                {formik.touched.confirmPassword && formik.errors.confirmPassword && <IonLabel className="error__label">{formik.errors.confirmPassword}</IonLabel>}
               
                
                    <IonButton className='button__action ion-margin-vertical' onClick={()=>formik.handleSubmit()}>
                    {translate('continue')}
                    </IonButton>
                 </form>
                </FormCard>
                
            </PageContainer>
        </IonContent>
    </IonPage>
  )
}

export default ChangePassword
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 12px 32px;
  h4{
    font-weight: 400;
font-size: 24px;
line-height: 32px;
    align-self: center;
    margin-top: 0;
    margin-bottom: 24px;
  }
  h5{
    font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
margin-top:24px;
  }
  ion-label{
    font-size:12px;
  }
  a{
    align-self: center;
    margin-top: 24px;
  }
`;
const CustomInput = styled(IonInput)`
  background: white;
  border: 1px solid #eff0f7;
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 46px;

`;

const BottomDiv = styled.div`
    position: absolute;
    bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    p{
        margin-bottom: 0;
        font-size: 14px;
    }
`
const FormCard=styled.div`
  background: #C0CDD9;
  box-shadow: 0px 0.5px 1px 2px rgba(0, 0, 0, 0.11);
  border-radius: 24px;
  width:100%;
  padding: 16px;
  p{
    font-weight: 500;
font-size: 14px;
line-height: 20px;
margin-bottom: 6px;
  }

`